import ReiLogo from "../../assets/images/reiwallet_w.png";
import store from "../../store";
import {saveAccount, saveLoading, saveSignature, saveType, saveWalletList} from "../../store/reducer";

import {SIG_WORD} from "../../utils/constant.js";
import {App} from "antd";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import styled from "styled-components";
import ChromeImg from  "../../assets/images/chrome.svg";

const RhtBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 12px;
    span{
        opacity: 0.7;
        color: #fff;
    }
    img{
        border: 0;
    }

`

export default function Rei({handleClose}){
    const {  notification } = App.useApp();
    const {t } = useTranslation();
    const walletList = useSelector(store => store.walletList);


    const ReiConnect = async () =>{

        const {ckb} = window.rei;
        if (typeof ckb !== "undefined") {

            await ckb.request({method:"ckb_switchNetwork",data:import.meta.env.VITE_CHAIN === "testnet"?"testnet":"mainnet"})


            const account = await ckb.request({method:"ckb_requestAccounts"});
            const pubkey = await ckb.request({method:"ckb_getPublicKey"});
            return { account, pubkey };
        } else {
            throw new Error(t("error.reiNOInstall"));
        }

    }

    const onConnect = async() =>{

        store.dispatch(saveLoading(true));
        try {
            let rlt = await ReiConnect();

            let signature = await window.rei?.ckb.request({method:"ckb_signMessage",data:{message:SIG_WORD}})

            let ckb = {
                account : rlt.account,
                type:"rei",
                signature
            }

            store.dispatch(saveWalletList({
                btc:walletList.btc,
                ckb
            }));


            store.dispatch(saveAccount(rlt.account));
            store.dispatch(saveType("rei"));
            store.dispatch(saveSignature(signature));
            store.dispatch(saveLoading(false));
            handleClose()
        } catch (error) {
            console.error(error);

            notification.error({
                message:  t("error.rei"),
                description:error.message || error.reason
            });
            store.dispatch(saveLoading(false));
        }
    }

    return <dl className="pc">
        <dt>
            <img src={ReiLogo} alt=""/>
        </dt>
        <dd>
            <span onClick={() => onConnect()}>REI Wallet</span>

            {
                !window.rei?.ckb &&
                <a href="https://chromewebstore.google.com/detail/rei-wallet/jacbgghlojlggfgljfhhlcddicacmbek"
                   target="_blank" rel="noreferrer"><RhtBox>
                    <span>{t("Download")} 👉 </span>
                    <img
                        src={ChromeImg} alt="" className="chrome"/>
                </RhtBox>
                </a>
            }

        </dd>
    </dl>
}
